<script setup>

</script>

<template>
  <div id="invoice">电子发票</div>
</template>

<style lang="scss" scoped>
#invoice {
  min-height: 100%;
}
</style>
